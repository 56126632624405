import { OVField, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity({ name: 'QueueLimit', api: 'yardlink' })
export class QueueLimitModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  priorityGroup: string;

  @OVField({ type: 'string' })
  priority: string;

  @OVField({ type: 'number' })
  limit: number;
}
