import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { VehicleType } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: VehicleType,
      api: 'adminlink',
      createTitle: 'Vehicle Type',
      route: ['vehicle-type'],
      tableTitle: 'Vehicle Type',
      permissionId: 911,
    }),
  ],
})
export class VehicleTypeModule {}
