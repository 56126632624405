import { OVField, OVTable, OVEntity } from '@ov-suite/ov-metadata';
import { QueuePriorityModel } from './queue-priority.model';
import { QueueLimitModel } from './queue-limit.model';

@OVEntity({ name: 'Queue', api: 'yardlink', tableName: 'queue' })
@OVTable<QueueModel>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    id: 'key2',
    key: 'key',
    title: 'Key',
    type: 'string',
  },
  {
    key: 'capacity',
    title: 'Key',
    type: 'number',
  },
])
export class QueueModel {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    tooltip: 'Name',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Key',
    placeholder: 'Required',
    tooltip: 'Key',
    required: true,
  })
  key: string;

  @OVField({
    type: 'string',
    title: 'Capacity',
    placeholder: 'Key',
    tooltip: 'Key',
    required: true,
  })
  capacity: number;

  @OVField({ type: () => [QueuePriorityModel] })
  priorityList: QueuePriorityModel[];

  @OVField({ type: () => [QueueLimitModel] })
  limits: QueueLimitModel[];

  domainId: number;
}
