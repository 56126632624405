import { OVField, OVForm, OVSidebar, OVTable, OVEntity } from '@ov-suite/ov-metadata';
import { UserRep } from '@ov-suite/models-idm';
import { GeofenceAlert } from "./geofence-alert.model";

@OVEntity({ name: 'GeofenceAlertUser', api: 'yardlink', tableName: 'geofence_alert_user' })
@OVForm([['#Geofence Alert Details'], ['user']])
@OVSidebar([[''], [''], ['id']])
@OVTable<GeofenceAlertUser>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number',
  },
])
export class GeofenceAlertUser {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  //
  @OVField({ type: () => UserRep })
  user: Partial<UserRep>;

  @OVField({ type: () => GeofenceAlert })
  geofenceAlert: GeofenceAlert;
}
