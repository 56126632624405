import { OVEntity, OVField, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import swal from 'sweetalert2';
import { PermissionType } from '@ov-suite/helpers-shared';

@OVEntity({ name: 'QueuePartial' })
export class QueuePartial {
  @OVField({ type: 'number' })
  id: number;

  @OVSearchable()
  @OVField({ type: 'string' })
  name: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  key: string;
}

@OVEntity({ name: 'QueueItem', api: 'yardlink', tableName: 'queue_item' })
@OVTable<QueueItem>([
  {
    key: 'name',
    title: 'Registration',
    type: 'string',
  },
  {
    key: 'queueDate',
    title: 'Queue Date',
    type: 'date-time',
  },
  {
    key: 'createDate',
    title: 'Created Date',
    type: 'date-time',
  },
  {
    key: 'priorityLevel',
    title: 'Priority',
    type: 'number',
  },
  {
    key: 'attempts',
    title: 'Contact Attempts',
    type: 'number',
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actions',
    disableSorting: true,
    disableFiltering: true,
    keys: ['id'],
    buttons: [
      {
        classes: 'btn btn-success btn-sm m-1',
        icon: 'fa fa-angle-double-up text-white',
        permissionId: 601,
        permissionType: PermissionType.update,
        action: async (item, { apiService }) => {
          item.priorityLevel += 1;
          await apiService.update({ entity: QueueItem, item: { id: item.id, priorityLevel: item.priorityLevel } }).then(updatedItem => {
            console.log({ updatedItem });
            swal
              .fire({
                title: 'Approved',
                type: 'success',
                confirmButtonText: 'Done',
              })
              .then(items => console.log({ items }))
              .catch(error => console.log({ error }));
          });
        },
      },
      {
        classes: 'btn btn-primary btn-sm m-1',
        icon: 'fa fa-angle-double-down text-white',
        permissionId: 601,
        permissionType: PermissionType.update,
        action: async (item, { apiService }) => {
          item.priorityLevel -= 1;
          await apiService.update({ entity: QueueItem, item: { id: item.id, priorityLevel: item.priorityLevel } }).then(() => {
            swal
              .fire({
                title: 'Approved',
                type: 'success',
                confirmButtonText: 'Done',
              })
              .then(items => console.log({ items }))
              .catch(error => console.log({ error }));
          });
        },
      },
      {
        icon: 'fa fa-trash',
        classes: 'btn-sm btn-danger m-1',
        tooltip: 'Remove From Queue',
        permissionId: 601,
        permissionType: PermissionType.delete,
        action: () => {},
        actionType: 'delete',
      },
    ],
  },
])
export class QueueItem {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    required: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    tooltip: 'Name',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Identifier',
    placeholder: 'Required',
    tooltip: 'Identifier',
    required: true,
  })
  identifier: string;

  @OVField({
    type: 'string',
    title: 'Priority Level',
    placeholder: 'Key',
    tooltip: 'Priority Level',
  })
  priorityLevel: number;

  @OVField({ type: 'date' })
  queueDate: Date;

  @OVField({ type: 'date' })
  createDate: Date;

  @OVField({ type: 'number' })
  attempts: number;

  active: boolean;

  domainId: number;
}
