import { Routes } from '@angular/router';
import { AdminLayoutComponent, RouteInfo } from '@ov-suite/ui';
import { AuthGuard } from '@ov-suite/authguard-angular';

export const angularRoutes: Routes = [
  {
    path: '',
    redirectTo: 'domain',
    pathMatch: 'full'
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('@ov-suite/authguard-angular').then(m => m.UnauthorizedModule),
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [ AuthGuard ],
    children: [
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      // },
      {
        path: 'domain',
        loadChildren: () => import('./modules/domain/domain.module').then(m => m.DomainModule),
      },
      {
        path: 'area',
        loadChildren: () => import('./modules/area/area.module').then(m => m.AreaModule),
      },
      {
        path: 'factory',
        loadChildren: () => import('./modules/factory/factory.module').then(m => m.FactoryModule),
      },
      {
        path: 'customer',
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
      },
      {
        path: 'customer-group',
        loadChildren: () => import('./modules/customer-group/customer-group.module').then(m => m.CustomerGroupModule),
      },
      {
        path: 'tag',
        loadChildren: () => import('./modules/tag/tag.module').then(m => m.TagModule),
      },
      {
        path: 'factory-area',
        loadChildren: () => import('./modules/factory-area/factory-area.module').then(m => m.FactoryAreaModule),
      },
      {
        path: 'equipment-type',
        loadChildren: () => import('./modules/equipment-type/equipment-type.module').then(m => m.EquipmentTypeModule),
      },
      {
        path: 'equipment',
        loadChildren: () => import('./modules/equipment/equipment.module').then(m => m.EquipmentModule),
      },
      {
        path: 'product-category',
        loadChildren: () => import('./modules/product-category/product-category.module').then(m => m.ProductCategoryModule),
      },
      {
        path: 'product',
        loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'product-sku',
        loadChildren: () => import('./modules/product-sku/product-sku.module').then(m => m.ProductSkuModule),
      },
      {
        path: 'transporter',
        loadChildren: () => import('./modules/transporter/transporter.module').then(m => m.TransporterModule),
      },
      {
        path: 'vehicle-class',
        loadChildren: () => import('./modules/vehicle-class/vehicle-class.module').then(m => m.VehicleClassModule),
      },
      {
        path: 'vehicle',
        loadChildren: () => import('./modules/vehicle/vehicle.module').then(m => m.VehicleModule),
      },
      {
        path: 'vehicle-type',
        loadChildren: () => import('./modules/vehicle-type/vehicle-type.module').then(m => m.VehicleTypeModule),
      },
      {
        path: 'inventory-location',
        loadChildren: () => import('./modules/inventory-location/inventory-location.module').then(m => m.InventoryLocationModule),
      },
    ],
  },
];

export const sidebarRoutes: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'dashboard',
    icontype: 'fa fa-tachometer',
    type: 'link',
  },
  {
    path: '/',
    title: 'General setup',
    type: 'sub',
    collapse: 'general-setup',
    icontype: 'fa fa-plus-circle',
    children: [
      { path: 'domain', title: 'Domains', ab: 'D', permissionId: 901 },
      { path: 'area', title: 'Areas', ab: 'A', permissionId: 902 },
      { path: 'customer', title: 'Customers', ab: 'C', permissionId: 903 },
      { path: 'factory', title: 'Factories', ab: 'F', permissionId: 904 },
      { path: 'product', title: 'Products', ab: 'P', permissionId: 905 },
      { path: 'equipment', title: 'Equipment', ab: 'E', permissionId: 906 },
      { path: 'tag', title: 'Tags', ab: 'T', permissionId: 907 },
      { path: 'transporter', title: 'Transporter', ab: 'TR', permissionId: 908 },
      { path: 'vehicle-class', title: 'Vehicle Classes', ab: 'VC', permissionId: 909 },
      { path: 'vehicle', title: 'Vehicle', ab: 'V', permissionId: 910 },
      { path: 'vehicle-type', title: 'Vehicle Type', ab: 'VT', permissionId: 911 },
    ],
  },
];
