import { OVEntity, OVField } from '@ov-suite/ov-metadata';

@OVEntity('TurnaroundTimeViolationUser', 'yardlink')
export class YardTurnaroundTimeViolationUserModel {
  @OVField({
    type: 'number',
  })
  id: number;

  @OVField({
    type: 'number',
  })
  userId: number;

  @OVField({
    type: 'number',
  })
  violationId: number;

  @OVField({
    type: 'boolean',
  })
  active: boolean;
}
