import { OVField, OVForm, OVSidebar, OVTable, OVEntity } from '@ov-suite/ov-metadata';
import { PermissionType } from '@ov-suite/helpers-shared';

@OVEntity({ name: 'Geofence', api: 'yardlink', tableName: 'geofence' })
@OVForm([['#Geofence Details'], ['name']])
@OVSidebar([[''], [''], ['id']])
@OVTable<Geofence>([
  {
    key: 'id',
    title: 'Unique ID',
    type: 'number',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'description',
    title: 'Description',
    type: 'string',
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actionsExtended',
    buttons: [
      {
        routerLink: () => ['geofence/management'],
        queryParams: geofence => ({ id: geofence.id }),
        icon: 'fa fa-edit',
        classes: 'btn-sm m-1',
      },
      {
        icon: 'fa fa-trash',
        classes: 'btn-sm btn-danger m-1',
        tooltip: 'Delete',
        permissionId: 602,
        permissionType: PermissionType.delete,
        action: () => {},
        actionType: 'delete',
      },
    ],
    keys: ['id'],
    disableFiltering: true,
  },
])
export class Geofence {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    tooltip: 'Name',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Description',
    placeholder: 'Required',
    tooltip: 'Description',
    required: true,
  })
  description: string;

  @OVField({
    type: 'string',
    title: 'GeoJSON',
    placeholder: 'Required',
    tooltip: 'GeoJSON',
    required: false,
  })
  geojson: string;

  domainId: number;

  key: string;
}
