import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';

@OVEntity('TrackerInformation', 'yardlink')
@OVTable<TrackerInformation>([
  {
    key: 'id',
    filterKey: 'id',
    title: 'ID',
    type: 'number',
    disableFiltering: true,
    hideColumnKey: 'id',
  },
  {
    key: 'imei',
    filterKey: 'imei',
    title: 'Imei',
    type: 'string',
    disableFiltering: false,
  },
  {
    key: 'timestamp',
    filterKey: 'timestamp',
    title: 'Last Checked',
    type: 'date-time',
    disableFiltering: false,
  },
  {
    key: 'used',
    filterKey: 'used',
    title: 'Entries Performed',
    type: 'number',
    disableFiltering: false,
  },
  {
    key: 'battery',
    filterKey: 'battery',
    title: 'Current Battery %',
    type: 'number',
    disableFiltering: false,
  },
  {
    key: 'avgBattery',
    filterKey: 'avgBattery',
    title: 'Average Battery %',
    type: 'number',
    disableFiltering: false,
  },
])
export class TrackerInformation {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  imei: string;

  @OVField({ type: 'date' })
  timestamp: Date;

  @OVField({ type: 'number' })
  avgBattery: number;

  @OVField({ type: 'number' })
  battery: number;

  @OVField({ type: 'number' })
  used: number;
}
