import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';
import { PermissionType } from '@ov-suite/helpers-shared';

@OVEntity('TurnaroundTimeViolation', 'yardlink')
@OVTable<YardTurnaroundTimeViolationModel>([
  {
    key: 'id',
    filterKey: 'id',
    title: 'ID',
    type: 'number',
    disableFiltering: true,
    hideColumnKey: 'id',
  },
  {
    key: 'duration',
    filterKey: 'duration',
    title: 'Duration',
    type: 'number',
    disableFiltering: false,
  },
  {
    key: 'active',
    filterKey: 'active',
    title: 'Active',
    type: 'boolean',
    disableFiltering: false,
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actionsBasic',
    buttons: [
      {
        // routerLink: pi => ['/workflow-processes/view', `${pi.id}`],
        icon: 'fa fa-edit',
        permissionType: PermissionType.update,
        action: () => {},
        actionType: 'edit',
        classes: 'btn-sm m-1',
      },
      {
        icon: 'fa fa-trash',
        classes: 'btn-sm btn-danger m-1',
        tooltip: 'Delete',
        permissionId: 602,
        permissionType: PermissionType.delete,
        action: () => {},
        actionType: 'delete',
      },
    ],
    keys: ['id'],
    disableFiltering: true,
  },
])
export class YardTurnaroundTimeViolationModel {
  @OVField({
    type: 'number',
  })
  id: number;

  @OVField({
    type: 'number',
  })
  duration: number;

  @OVField({
    type: 'boolean',
  })
  active: boolean;

  domainId: number;
}
