import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Customer } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Customer,
      api: 'adminlink',
      createTitle: 'Customer',
      route: ['customer'],
      tableTitle: 'Customer',
      tabs: [
        {
          path: '/customer',
          title: 'Customer',
        },
        {
          path: '/customer-group',
          title: 'Customer Group',
        },
      ],
      permissionId: 903,
    }),
  ],
})
export class CustomerModule {}
