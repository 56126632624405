import { OVEntity, OVField } from '@ov-suite/ov-metadata';

export interface TrackerMetadata {
  alt?: number;
  bat?: number;
  lat?: number;
  lon?: number;
  sos?: boolean;
  imei?: string;
  fuel1?: number;
  fuel2?: number;
  fuel3?: number;
  fuel4?: number;
  speed?: number;
  tamper?: boolean;
  gpslock?: boolean;
  movement?: boolean;
  timestamp?: Date;
  event_timestamp?: Date;
  proximity_violation_state?: number;
  proximity_violation_source?: string;
  proximity_violation_duration?: number;
}

@OVEntity('TrackersLog', 'yardlink')
export class TrackersLog {
  id: number;

  @OVField({ type: 'string' })
  imei: string;

  @OVField({ type: Date })
  timestamp: Date;

  @OVField({ type: 'json' })
  metadata: TrackerMetadata;
}
