import {
  OVEntity,
  OVField,
  OVForm,
  OVSidebar,
  OVTable
} from '@ov-suite/ov-metadata';
import { Geography } from "@ov-suite/helpers-shared";

@OVEntity('VehicleTrack', 'yardlink')
@OVForm([['#Vehicle Track'], ['value']])
@OVSidebar([['id']])
@OVTable<VehicleTrack>([
  {
    key: 'id',
    title: 'Vehicle Tracks',
    type: 'string'
  }
])
export class VehicleTrack {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'IMEI',
    placeholder: 'Required',
    required: true
  })
  imei: string;

  @OVField({
    type: 'date-time',
    title: 'Timestamp',
    placeholder: 'Required',
    required: true
  })
  timestamp: Date;

  @OVField({
    type: 'string',
  })
  coordinates: Geography
}
