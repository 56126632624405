import { OVEntity, OVField } from '@ov-suite/ov-metadata';

@OVEntity('ExtraColumn', 'yardlink')
export class ExtraColumnModel {
  id: number;

  @OVField({ type: 'string' })
  title: string;

  @OVField({ type: 'string' })
  value: string;
}
