import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { VehicleClass } from '@ov-suite/models-admin';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: VehicleClass,
      api: 'adminlink',
      createTitle: 'Vehicle Class',
      route: ['vehicle-class'],
      tableTitle: 'Vehicle Class',
      permissionId: 909,
    }),
  ],
})
export class VehicleClassModule {}
