import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Domain } from '@ov-suite/models-admin';
import { HistoricalExtraColumnsModel } from '@ov-suite/models-yard';
import { getFieldMetadata } from '@ov-suite/ov-metadata';

const extraColumnEntityName = getFieldMetadata(HistoricalExtraColumnsModel).name;

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Domain,
      api: 'adminlink',
      createTitle: 'Domain',
      route: ['domain'],
      tableTitle: 'Domain',
      permissionId: 901,
      extraColumnEntity: extraColumnEntityName,
    }),
  ],
})
export class DomainModule {}
