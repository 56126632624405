import { OVEntity, OVField, OVForm, OVTable } from '@ov-suite/ov-metadata';

@OVEntity('LiveFeedExtraColumn', 'yardlink')
@OVTable<LiveFeedExtraColumnsModel>([
  {
    type: 'number',
    key: 'id',
    title: 'ID',
  },
  {
    type: 'string',
    key: 'title',
    title: 'Title',
  },
  {
    type: 'string',
    key: 'variableName',
    title: 'Variable Name',
  },
])
@OVForm([['title', 'variableName']])
export class LiveFeedExtraColumnsModel {
  @OVField({ type: 'number', generated: true, readonly: true })
  id: number;

  @OVField({ type: 'string', title: 'Title', required: true })
  title: string;

  @OVField({ type: 'string', title: 'Variable Name', tooltip: 'Name of the variable as it appears on Flowable', required: true })
  variableName: string;
}
