import { ErrorHandler, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ErrorService {
  private errorList: BehaviorSubject<OVError[]> = new BehaviorSubject<
    OVError[]
  >([]);

  public errors: Observable<OVError[]> = this.errorList.asObservable();

  addError(name: string | number, message: string) {
    this.errorList.next([...this.errorList.getValue(), { name, message }]);
  }

  clear() {
    this.errorList.next([]);
  }
}

@Injectable()
export class MyErrorHandler implements ErrorHandler {
  constructor(private readonly errorService: ErrorService) {}

  handleError(error) {
    if (Array.isArray(error)) {
      error.forEach(err => this.sortError(err));
    } else {
      this.sortError(error);
    }
  }

  private sortError(error) {
    if (error?.rejection?.networkError) {
      this.errorService.addError(
        error.rejection.networkError.status,
        error.rejection.networkError.statusText
      );
    }
    if (error?.extensions?.code) {
      this.errorService.addError(error.extensions.code, error.message);
    }
  }
}

export class OVError {
  name: string | number;
  message: string;
}
