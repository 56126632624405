import { OVField, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity({ name: 'QueuePriority', api: 'yardlink', tableName: 'queue' })
export class QueuePriorityModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  priorityGroup: string;

  @OVField({ type: 'string' })
  priority: string;
}
