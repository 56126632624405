import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';
import { ExceptionTypeModel } from './exception-type.model';

@OVEntity('ExceptionLog', 'yardlink')
@OVTable<ExceptionLogModel>([
  {
    type: 'number',
    key: 'id',
    filterKey: 'id',
    title: 'ID',
    disableFiltering: true,
  },
  {
    type: 'string',
    key: 'statusCode',
    title: 'Status Code',
  },
  {
    type: 'string',
    key: 'errorMessage',
    title: 'Error Message',
  },
  {
    type: 'date-time',
    key: 'timestamp',
    title: 'Timestamp',
  },
])
export class ExceptionLogModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: () => ExceptionTypeModel })
  exceptionType: ExceptionTypeModel;

  @OVField({ type: 'string' })
  statusCode: string;

  @OVField({ type: 'string' })
  errorMessage: string;

  @OVField({ type: 'date-time' })
  timestamp: Date;
}
